var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?[_c('div',{staticClass:"pl-4 pr-4 pt-4"},[_c('el-skeleton',{attrs:{"rows":12,"loading":_vm.isLoading,"animated":""}})],1)]:[_c('el-tree',{ref:"vuetree",staticClass:"tree-wrap",attrs:{"id":"tree","node-key":"id","data":_vm.contactFolderTree,"props":_vm.defaultProps,"highlight-current":"","allow-drop":_vm.allowDrop,"allow-drag":_vm.allowDrag,"expand-on-click-node":false,"default-expanded-keys":_vm.defaultExpand},on:{"node-click":_vm.nodeClick,"node-contextmenu":_vm.rightClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{class:`zm-unselect zm-folder-info zm-dropable ${_vm.isFolderDefault(data.id) ? '': 'zm-dragable'}`,attrs:{"id":data.id,"type":data.view,"name":data.name}},[_c('el-popover',{attrs:{"placement":"bottom-start","open-delay":500,"trigger":"hover","disabled":data.id === _vm.FOLDER_DEFAULT.ROOT || _vm.checkPoper}},[_c('div',[_c('b',[_vm._v(_vm._s(node.label))]),_vm._v(" "),_c('br'),(
                                (data.id != _vm.FOLDER_DEFAULT.TRASH) &
                                (data.id != _vm.FOLDER_DEFAULT.MAILING_LIST)
                            )?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zhMsg.addressBooksLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.n || 0))])]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TRASH)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.items")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(data.n || 0))])]):_vm._e(),(data.s)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"folder-info-left"},[_vm._v(_vm._s(_vm.$t("zimbra.zmMsg.fSizeLabel")))]),_c('span',{staticClass:"folder-info-right"},[_vm._v(_vm._s(_vm.formatSize(data.s)))])]):_vm._e()]),_c('span',{staticClass:"custom-tree-node",attrs:{"slot":"reference"},slot:"reference"},[(
                                data.id != _vm.FOLDER_DEFAULT.CONTACTS &&
                                data.id != _vm.FOLDER_DEFAULT.EMAIL_CONTACT &&
                                data.id != _vm.FOLDER_DEFAULT.ROOT &&
                                data.id != _vm.FOLDER_DEFAULT.TRASH &&
                                data.id != _vm.FOLDER_DEFAULT.MAILING_LIST &&
                                data.isLink == undefined
                            )?_c('span',[_c('i',{class:[
                                    'ic-folder ic-with-color',
                                    _vm.isActive(data) ? 'active' : '',
                                ],style:(`padding-right: 30px; margin-left: -5px; zoom: 0.9; filter: ${_vm.convertHexToFilterCss(
                                    data
                                )};  --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.isLink)?_c('span',[_c('i',{class:[
                                    'ic-folder-copy ic-with-color',
                                    _vm.isActive(data) ? 'active' : '',
                                ],style:(`padding-right: 30px; margin-left: -6px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}; --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.CONTACTS)?_c('span',[_c('i',{class:[
                                    'ic-contact ic-with-color',
                                    _vm.isActive(data) ? 'active' : '',
                                ],style:(`
                                    padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}; --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.MAILING_LIST)?_c('span',[_c('i',{class:[
                                    'ic-distribution',
                                    _vm.isActive(data) ? 'active' : '',
                                ],staticStyle:{"padding-right":"30px","margin-left":"-8px"}})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.EMAIL_CONTACT)?_c('span',[_c('i',{class:[
                                    'ic-email-contact',
                                    _vm.isActive(data) ? 'active' : '',
                                ],style:(`
                                    padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}; --icColor: ${_vm.convertHexToFilterCss(data)}`)})]):_vm._e(),(data.id == _vm.FOLDER_DEFAULT.TRASH)?_c('span',[_c('i',{class:[
                                    'ic-trash',
                                    _vm.isActive(data) ? 'active' : '',
                                ],staticStyle:{"padding-right":"30px","margin-left":"-7px","zoom":"1.05"}})]):_vm._e(),((data.isLink && data.perm) || data.isLink== undefined)?_c('span',[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(node.label)+" ")])]):_vm._e(),(data.isLink && data.perm == undefined)?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(node.label)+" ")]):_vm._e(),(!_vm.isContactSelector)?_c('button',{staticClass:"icon-caret float-right",attrs:{"id":`caret_${data.id}`},on:{"click":function($event){$event.stopPropagation();return ((e) => _vm.rightClick(e, data, node)).apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-caret-bottom"})]):_vm._e()])])],1)}}])}),(_vm.showTreeTag)?_c('el-tree',{ref:"vuetreeTag",staticClass:"tree-zimlet tree-wrap",attrs:{"node-key":"id","id":"tree","data":_vm.folderTag,"props":_vm.defaultProps,"allow-drop":_vm.allowDrop,"allow-drag":_vm.allowDrag,"expand-on-click-node":false,"default-expand-all":""},on:{"node-click":_vm.nodeClick,"node-contextmenu":_vm.rightClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{class:`zm-unselect zm-folder-info zm-dropable ${_vm.isFolderDefault(data.id) ? '': 'zm-dragable'}`,attrs:{"id":data.id,"type":data.type,"name":data.name}},[_c('el-popover',{attrs:{"placement":"bottom","popper-class":"zm-tooltip","open-delay":500,"trigger":"hover","disabled":_vm.checkPoper}},[_c('b',[_vm._v(_vm._s(node.label))]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[(
                            data.id == _vm.FOLDER_DEFAULT.TAG ||
                            data.type == _vm.SIDEBAR_NODE.TAG
                        )?_c('span',[_c('i',{class:['ic-tag', _vm.isActive(data) ? 'active' : ''],style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                                    data
                                )}`)})]):_vm._e(),_c('span',[_vm._v(_vm._s(node.label))]),_c('button',{staticClass:"icon-caret float-right",attrs:{"id":`caret_${data.id}`},on:{"click":function($event){$event.stopPropagation();return ((e) => _vm.rightClick(e, data, node)).apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-caret-bottom"})])])])],1)}}],null,false,4070634569)}):_vm._e(),(_vm.showTreeSearch)?_c('el-tree',{ref:"tree",staticClass:"tree-wrap",attrs:{"data":_vm.treeFolderSearchData,"node-key":"id","props":_vm.defaultProps1,"default-expanded-keys":[_vm.FOLDER_DEFAULT.ROOT],"expand-on-click-node":false,"highlight-current":""},on:{"node-click":_vm.nodeClick,"node-contextmenu":_vm.rightClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{class:`zm-unselect zm-folder-info zm-dropable ${_vm.isFolderDefault(data.id) ? '': 'zm-dragable'}`,attrs:{"type":_vm.FOLDER_VIEW.SEARCH,"id":data.id,"name":data.name}},[(data.id != _vm.FOLDER_DEFAULT.ROOT)?_c('span',[_c('i',{staticClass:"ic-folder",style:(`padding-right: 30px; margin-left: -8px;filter: ${_vm.convertHexToFilterCss(
                            data
                        )}`)})]):_vm._e(),_c('span',[_vm._v(_vm._s(node.label))]),_c('button',{staticClass:"icon-caret float-right",attrs:{"id":`caret_${data.id}`},on:{"click":function($event){$event.stopPropagation();return ((e) => _vm.rightClick(e, data, node)).apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-caret-bottom"})])])}}],null,false,1083471124)}):_vm._e(),(_vm.showTreeZimlet)?_c('div',[_c('ZimletTree',{attrs:{"highlightDefault":_vm.highlightDefault,"showInfoFolder":_vm.showInfoFolder}})],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }