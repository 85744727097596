<template>
    <el-dialog
        :title="$t('zimbra.zmMsg.attachContact')"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
        class="zimbra-dialog zm-dialog-attach-email "
        @close="onclose"
        @opened="opened"
        :close-on-click-modal="false"
        width="650px"
    >
        <div>
            <el-row>
                <el-col :span="10">
                    <input
                        ref="refInputKeyword"
                        class="el-text-control form-control"
                        v-model.trim="keyword"
                        @keyup.enter="handleSearchKeyWord"
                    />
                </el-col>
                <el-col :span="8" class="pl-3">
                    <el-button
                        class="el-button btn-email default"
                        @click="handleSearchKeyWord"
                        >{{ $t("zimbra.zhMsg.search") }}</el-button
                    >
                </el-col>
                <el-col :span="6" class="text-right">
                    <el-button
                        :class="['el-button', currentPage === 0 ? 'noclick' : '']"
                        @click="previousPage"
                    >
                        <i class="fi fi-rr-angle-double-small-left"></i>
                    </el-button>
                    <span v-if="listContact.length > 0" class="pl-3 pr-3">{{start}} - {{end}}</span>
                    <el-button
                        :class="['el-button', isLastPage ? 'noclick' : '']"
                        @click="nextPage"
                    >
                        <i class="fi fi-rr-angle-double-small-right"></i>
                    </el-button>
                </el-col>
            </el-row>
            <el-row class="pt-3 file-attach-wrap">
                <el-col :span="10">
                    <perfect-scrollbar class="attach-file">
                        <TreeFolderContact
                            ref="refTreeFolder"
                            :is-contact-selector="true"
                            :highlight-default="true"
                            :show-info-folder="true"
                            :show-tree-tag="false"
                            :show-tree-search="false"
                            :showTreeZimlet="false"
                            @onSelectFolder="onSelectFolder"
                        ></TreeFolderContact>
                    </perfect-scrollbar>
                </el-col>
                <el-col :span="14" class="pl-3">
                    <perfect-scrollbar style="while-space: nowrap">
                        <template v-if="isSearching">
                            <div class="p-5">
                                <el-skeleton :rows="6" :loading="isSearching" animated />
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="!listContact || listContact.length === 0">
                                <el-empty :description="$t('zimbra.ztMsg.noContacts')"></el-empty>
                            </template>
                            <template v-else>
                                <ul class="list-contact pl-2 pr-2">
                                    <template v-for="(item, idx) in listContact" >
                                        <li :key="idx" :class="['item-contact pt-2 pb-2', item.selected ? 'active': '']">
                                            <div class="d-flex">
                                                <el-checkbox v-model="item.selected"></el-checkbox>
                                                <div class="pl-3">
                                                    <div class="name">
                                                        <i class="fas fa fa-user pr-2"></i>
                                                        {{ item.fileAsStr == "" ? $t("zimbra.zhMsg.noName") : item.fileAsStr}}
                                                    </div>
                                                    <div class="email">
                                                        {{ item.email }}
                                                    </div>
                                                    <div class="mobile-phone">
                                                        {{ item.mobilePhone }}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </template>
                    </perfect-scrollbar>
                </el-col>
            </el-row>
        </div>
        <div v-html="$t('zimbra.zhMsg.attachmentLimitMsg', [maxSize])"></div>
        <span slot="footer" class="dialog-footer">
            <el-button class="el-button btn-email default" @click="onclose">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button
                class="el-button btn-email primary"
                type="primary"
                @click="handleAttachContact"
            >
                {{ $t("zimbra.zmMsg.attach") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import ContactUtils from "@/utils/contact-utils";
import TreeFolderContact from "@/components/modules/layouts/components/contacts/TreeFolderContact";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapGetters } from "vuex";
import {
    FOLDER_DEFAULT,
} from "@/utils/constants";
import _ from "lodash";
export default {
    name: "DialogEmailSelector",
    mixins: [CommonUtils, ContactUtils],
    components: {
        TreeFolderContact,
    },
    data() {
        return {
            dialogFormVisible: false,
            keyword: "",
            start: 0,
            end: 0,
            currentPage: 0,
            pageSize: 50,
            query: "",
            listEmail: [],
            isLastPage: false,
            listContact: [],
            listActiveContact: [],
            isSearching: true,
        };
    },
    computed: {
        ...mapGetters(["dataSidebarInBox", "maxSizeAttach"]),
        maxSize() {
            return this.formatSize(this.maxSizeAttach, true);
        }
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        show() {
            this.dialogFormVisible = true;
            this.getFolderContactApi();
        },
        onclose() {
            this.dialogFormVisible = false;
        },
        opened() {
            this.query = `inid:${FOLDER_DEFAULT.CONTACTS} not #type:group`;
            this.prepareSearch();
            this.$refs.refTreeFolder && this.$refs.refTreeFolder.setCurrentKey(FOLDER_DEFAULT.CONTACTS);
        },
        handleSearchKeyWord() {
            this.$refs.refInputKeyword.blur();
            this.query = `${_.escape(this.keyword)} not #type:group`;
            this.prepareSearch();
        },
        onSelectFolder(node) {
            let ids = null
            if(node.id.toString().includes(":")) {
               const _temp = node.id.split(":")
               ids = _temp[1]
            } else {
                ids = node.id
            }
            this.query = `inid:${ids} not #type:group`;
            this.prepareSearch();
        },
        handleAttachContact() {
            const listSelected = this.listContact.filter(e => e.selected);
            if (!listSelected || listSelected.length === 0) {
                return;
            }
            this.$emit("handleAttachContact", listSelected);
            this.dialogFormVisible = false;
        },
        prepareSearch() {
            const formData = {
                types: "contact",
                limit: `${this.pageSize}`,
                offset: "0",
                query: this.query,
                currentPage: 0
            };
            this.handleSearch(formData);
        },
        handleSearch(formData) {
            ZimbraMailService.searchRequest(formData).then((searchRequest) => {
                const searchResponse =
                    this.getResponseBody(searchRequest)["SearchResponse"];
                let listContact = searchResponse.cn || [];
                listContact = Array.isArray(listContact) ? listContact : [listContact];
                const _oldListContact = _.cloneDeep(this.listContact);
                this.listContact = listContact.map(e => {
                    const contact = _oldListContact.find(x => x.id == e.id);
                    return {
                        ...e,
                        ...this.convertArrayToObject(e.a, "n", "content"),
                        selected: contact ? contact.selected : false
                    };
                });
                this.listActiveContact = [];
                this.currentPage = formData.currentPage;
                this.start = this.pageSize * this.currentPage + 1;
                this.end = this.pageSize * this.currentPage + listContact.length;
                this.isLastPage = this.pageSize != listContact.length;
                this.isSearching = false;
            }).catch(() => {
                this.isSearching = false;
            });
        },
        previousPage() {
            const currentPage = this.currentPage - 1;
            const formData = {
                types: "contact",
                limit: `${this.pageSize}`,
                offset: currentPage * this.pageSize,
                query: this.query,
                currentPage: currentPage
            };
            this.handleSearch(formData);
        },
        nextPage() {
            const currentPage = this.currentPage + 1;
            const formData = {
                types: "contact",
                limit: `${this.pageSize}`,
                offset: currentPage * this.pageSize,
                query: this.query,
                currentPage: currentPage
            };
            this.handleSearch(formData);
        },
    },
};
</script>