<template>
    <el-dialog
        :title="$t('zimbra.zhMsg.attachFiles')"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
        class="zimbra-dialog zm-dialog-attach-email"
        @close="onclose"
        @opened="opened"
        :close-on-click-modal="false"
        width="650px"
    >
        <div>
            <el-row class="pt-3">
                <el-col :span="10" class="tree-briefcase">
                    <perfect-scrollbar class="attach-file">
                        <TreeBriefcase
                            ref="refTreeFolder"
                            :isBriefCaseSelector="true"
                            :includeRoot="false"
                            :highlight-default="true"
                            :show-info-folder="true"
                            :isForward="true"
                            @nodeClick="onSelectFolder"
                        ></TreeBriefcase>
                    </perfect-scrollbar>
                </el-col>
                <el-col :span="14" class="pl-3">
                    <perfect-scrollbar>
                        <template v-if="isSearching">
                            <div class="p-5">
                                <el-skeleton :rows="6" :loading="isSearching" animated />
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="!listBriefcase || listBriefcase.length === 0">
                                <el-empty :description="$t('zimbra.zhMsg.noResultsFound')"></el-empty>
                            </template>
                            <template v-else>
                                <ul class="list-briefcase pl-2 pr-2">
                                    <template v-for="(item, idx) in listBriefcase" >
                                        <li :key="idx" :class="['item-briefcase pt-2 pb-2', item.selected ? 'active': '']">
                                            <div class="d-flex">
                                                <el-checkbox v-model="item.selected"></el-checkbox>
                                                <div class="pl-3">
                                                    <div class="name">
                                                        <img class="ic-briefcase-doc" :src="item.mimeInfo.dataUri" :alt="item.mimeInfo.image" />
                                                        {{ item.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </template>
                    </perfect-scrollbar>
                </el-col>
            </el-row>
        </div>
        <div class="mt-5" v-html="$t('zimbra.zhMsg.attachmentLimitMsg', [maxSize])"></div>
        <div class="ZmAttachDialog-inline zm-unselect">
            <input type="checkbox" role="button" id="DWT46375_inlineCheckbox" class="mr-2"/>
            <label for="DWT46375_inlineCheckbox">{{$t('zimbra.zmMsg.inlineAttachmentOption')}}</label>
        </div>
        <span slot="footer" class="dialog-footer">
            <span class="mr-4" v-if="checkFileAttach">{{$t("zimbra.zmMsg.attachSelectMessage")}}</span>
            <el-button class="el-button btn-email default" @click="onclose">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button
                class="el-button btn-email primary"
                type="primary"
                @click="handleAttachBriefcase"
            >
                {{ $t("zimbra.zmMsg.attach") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import BriefcaseUtils from "@/utils/briefcase-utils";
import TreeBriefcase from '@/components/modules/briefcase/components/TreeBriefCase.vue'
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import { mapGetters } from "vuex";
import {
    FOLDER_VIEW,
    FOLDER_DEFAULT
} from "@/utils/constants";
import _ from "lodash";
export default {
    mixins: [CommonUtils, BriefcaseUtils],
    components: {
        TreeBriefcase,
    },
    data() {
        return {
            dialogFormVisible: false,
            listBriefcase: [],
            listActiveBriefcase: [],
            isSearching: true,
            checkFileAttach: false,
            FOLDER_DEFAULT: FOLDER_DEFAULT
        };
    },
    computed: {
        ...mapGetters([
            "maxSizeAttach",
            "localeCurrent",
            "timeZoneCurrent",
        ]),
        maxSize() {
            return this.formatSize(this.maxSizeAttach, true);
        }
    },
    watch: {
    },
    methods: {
        show() {
            this.checkFileAttach = false
            this.dialogFormVisible = true;
        },
        onclose() {
            this.dialogFormVisible = false;
        },
        opened() {
            this.query = `in:${FOLDER_VIEW.BRIEFCASE}`;
            this.prepareSearch();
        },
        onSelectFolder(node) {
            this.query = `in:"${_.escape(node.absFolderPath)}"`;
            this.prepareSearch();
        },
        handleAttachBriefcase() {
            const listSelected = this.listBriefcase.filter(e => e.selected);
            if (!listSelected || listSelected.length === 0) {
                this.checkFileAttach = true;
                return;
            }
            this.$emit("handleAttachBriefcase", listSelected);
            this.dialogFormVisible = false;
        },
        prepareSearch() {
            const formData = {
                query: this.query,
                offset: 0,
                limit: this.$configZimbra.LIMIT_SEARCH_BRIEFCASEBOX,
                sortBy: "subjAsc",
                locale: { content: this.localeCurrent.content },
                tz: { id: this.timeZoneCurrent.content },
                types: FOLDER_VIEW.DOCUMENT,
            };
            this.handleSearch(formData);
        },
        handleSearch(formData) {
            ZimbraMailService.searchRequest(formData).then((searchRequest) => {
                const searchResponse =
                    this.getResponseBody(searchRequest)["SearchResponse"];
                let listBriefcase = searchResponse.doc || [];
                listBriefcase = Array.isArray(listBriefcase) ? listBriefcase : [listBriefcase];
                const _oldListBriefcase = _.cloneDeep(this.listBriefcase);
                this.listBriefcase = listBriefcase.map(e => {
                    const briefcase = _oldListBriefcase.find(x => x.id == e.id);
                    let ct = e.ct.split(";")[0]
                    if (ct && ct.match(/;/)) {
                        ct = ct.split(";")[0]
                    }
                    const mimeInfo = ct ? ZmMimeTable.getInfo(ct) : null
                    e.mimeInfo = mimeInfo
                    return {
                        ...e,
                        ...this.convertArrayToObject(e.a, "n", "content"),
                        selected: briefcase ? briefcase.selected : false
                    };
                });
                this.listActiveBriefcase = [];
                this.isSearching = false;
            }).catch(() => {
                this.isSearching = false;
            });
        },
    },
};
</script>