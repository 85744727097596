<template>
    <li>
        <div
            :class="
                `item-inbox ${dataEmail.isUnread ? 'unread' : ''}
                ${isActive() ? 'active' : ''}
                ${isGroup ? 'group' : ''}
                ${isLastSelected ? 'item-inbox-selected' : ''}`
            "
            :style="{
                'background-image': `linear-gradient(to bottom, rgb(255,255,255) , ${this.setColorMsg()})`,
            }"
        >
            <el-popover
                v-if="isHasTag(dataEmail)"
                placement="top-start"
                trigger="hover"
            >
                <div
                    v-for="(item, idx) in getListTag(dataEmail)"
                    :key="idx"
                    class="zimbra-tag-list-tooltip"
                >
                    <div
                        class="icon-color"
                        :style="{
                            '--bgColorTag': getColor(item),
                        }"
                    ></div>
                    <div>{{ item ? item.name : "" }}</div>
                </div>
                <div
                    slot="reference"
                    :class="`zimbra-tag-item-wrap ${
                        isMultiTag(dataEmail) ? 'multi-tag' : ''
                    }`"
                    :style="{
                        '--bgColorTag': getColorTag(dataEmail),
                    }"
                >
                    <!-- <span class="test">&nbsp;</span> -->
                </div>
            </el-popover>
            <BaseAvatar
                :listEmailAddress="listEmailAvatar"
            />
            <div class="content">
                <div class="top-ib">
                    <div class="name">
                        <i
                            v-if="dataEmail.n > 1"
                            :class="`icon ${dataEmail.toggle ? 'down' : ''}`"
                            @click.stop="toggleSubItem()"
                        ></i>
                        <span class="ml-0"
                            @click.exact="handleSelectItem"
                            :id="`addressName_${dataEmail.id}`"
                        >
                            {{ addressName }}
                        </span>
                        <span v-if="dataEmail.n > 1" class="number-email">{{
                            dataEmail.n
                        }}</span>
                    </div>
                    <div class="control" @click.exact="handleSelectItem">
                        <el-tooltip :content="$t('zimbra.zhMsg.ALT_HIGH_PRIORITY')" placement="bottom" effect="light">
                            <div
                                v-if="dataEmail.isUrgent"
                                class="item-control"
                            >
                                <a href="javascript:;" class="ic ic-priority"></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbra.zhMsg.ALT_LOW_PRIORITY')" placement="bottom" effect="light">
                            <div
                                v-if="dataEmail.isLowPriority"
                                class="item-control"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-priority-down"
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbra.zmMsg.attachMail')" placement="bottom" effect="light">
                            <div
                                v-if="dataEmail.isAttach"
                                class="item-control"
                            >
                                <a href="javascript:;" class="ic ic-attach"></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbra.zhMsg.actionMarkRead')" placement="bottom" effect="light">
                            <div
                                v-if="dataEmail.isUnread && !viewOnly"
                                class="item-control item-control-hide"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-mail"
                                    @click.stop="
                                        actionItemEmail(
                                            dataEmail,
                                            EMAIL_ACTION.MARK_AS_READ
                                        )
                                    "
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbra.zhMsg.actionMarkUnread')" placement="bottom" effect="light">
                            <div
                                v-if="!dataEmail.isUnread && !viewOnly"
                                class="item-control item-control-hide"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-unread"
                                    @click.stop="
                                        actionItemEmail(
                                            dataEmail,
                                            EMAIL_ACTION.MARK_AS_UNREAD
                                        )
                                    "
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbra.zhMsg.actionDelete')" placement="bottom" effect="light">
                            <div
                                v-if="!viewOnly"
                                class="item-control item-control-hide"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-delete"
                                    @click.stop="actionItemEmail(dataEmail, EMAIL_ACTION.DELETE)"
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbraNokey.zimbra_setting_zimlet_archive')" placement="bottom" effect="light">
                            <div
                                v-if="!viewOnly"
                                class="item-control item-control-hide"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-archive1"
                                    @click.stop="actionItemEmail(dataEmail, EMAIL_ACTION.ARCHIVE)"
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbraNokey.zimbra_mailbox_assignStar')" placement="bottom" effect="light">
                            <div
                                v-if="
                                    !isStar(
                                        mapppingStarConv,
                                        dataEmail.id,
                                        'cid'
                                    ) && !viewOnly
                                "
                                class="item-control item-control-hide"
                            >
                                <a
                                    href="javascript:;"
                                    class="ic ic-star1"
                                    @click.stop="toggleStar"
                                ></a>
                            </div>
                        </el-tooltip>
                        <el-tooltip :content="$t('zimbraNokey.zimbra_zimbraNokey_unstar')" placement="bottom" effect="light">
                            <div class="item-control">
                                <a
                                    v-if="
                                        isStar(
                                            mapppingStarConv,
                                            dataEmail.id,
                                            'cid'
                                        )
                                        && !viewOnly
                                    "
                                    href="javascript:;"
                                    :class="`ic ${
                                        isStar(
                                            mapppingStarConv,
                                            dataEmail.id,
                                            'cid'
                                        )
                                            ? 'ic-star1-act'
                                            : 'ic-star'
                                    }`"
                                    @click.stop="toggleStar"
                                >
                                </a>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="ct" @click.exact="handleSelectItem">
                    <div class="head">
                        <div class="title">
                             <el-popover
                                placement="bottom-start"
                                :open-delay="1000"
                                :disabled="!dataEmail.fr || dataEmail.fr.length == 0"
                                trigger="hover">
                                <div>{{ dataEmail.fr}}</div>
                                <div v-if="activeNodeSidebar.id == FOLDER_DEFAULT.DRAFTS">{{ $t("zimbra.zmMsg.accountDownloadToFolder", [activeNodeSidebar.name]) }}</div>
                                <span slot="reference">{{
                                        dataEmail.su ||
                                        $t("zimbra.zhMsg.noSubject")
                                    }}
                                </span>
                            </el-popover>
                        </div>
                        <div class="time">
                            {{ this.renderTime(this.dataEmail.d) }}
                        </div>
                    </div>
                    <el-popover
                        v-model="showPopoverExcerpt"
                        placement="bottom-start"
                        :open-delay="1000"
                        :disabled="!dataEmail.fr || dataEmail.fr.length == 0"
                        trigger="hover">
                        <div>{{ dataEmail.fr }}</div>
                        <div v-if="activeNodeSidebar.id == FOLDER_DEFAULT.DRAFTS">{{ $t("zimbra.zmMsg.accountDownloadToFolder", [activeNodeSidebar.name]) }}</div>
                        <div slot="reference" class="desc" v-if="this.showFragments.content">
                            {{ dataEmail.fr }}
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>

    </li>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
// import SubItemMailBox from "../sub-item-mail-box";
import ListColor from "@/components/modules/layouts/color.json";
import {
    EMAIL_FLAG,
    EMAIL_ACTION,
    FOLDER_EXTRA_ACTION,
    CONV_ORDER,
    FOLDER_DEFAULT
} from "@/utils/constants";
import { mapGetters } from "vuex";
import _ from "lodash";
import hotkeys from "hotkeys-js";
import BaseAvatar from "@/components/modules/base/base-avatar/base-avatar.vue";
export default {
    name: "ItemMailBox",
    mixins: [CommonUtils, EmailUtils],
    props: {
        dataEmail: Object,
        activeEmail: Object,
        lastItem: Boolean,
        viewOnly: {
            type: Boolean,
            default: false,
        },
        lastSelected: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: {
        // SubItemMailBox,
        BaseAvatar
    },
    mounted() {
        this.$root.$on("toggleStar", () => {
            if (this.viewOnly) {
                return;
            }
            this.toggleStar();
        });

        setTimeout(() => {
            hotkeys("shift+o", this.handleToggleSubItem);
            hotkeys("ctrl+o", this.handleCloseSubItem);
            hotkeys("q", this.showExcerpt);
        }, 1000)

        var x = document.getElementById(`addressName_${this.dataEmail.id}`);
        x.addEventListener("contextmenu", this.rightClickAddressName);
    },
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            toggle: false,
            listColor: ListColor,
            listSubItem: [],
            color: "",
            from: [],
            to: [],
            cc: [],
            bcc: [],
            star: this.isStar(this.mapppingStarConv, this.dataEmail.id, "cid"),
            EMAIL_FLAG: EMAIL_FLAG,
            formatTime: "MMMM DD YYYY, h:mm:ss A",
            showPopoverExcerpt: false
        };
    },
    created() {
        this.initialData(this.dataEmail);
        this.dataEmail.toggle = this.dataEmail.toggle || false;
    },
    watch: {
        dataEmail() {
            this.initialData(this.dataEmail);
        },
        mapppingStarConv: {
            deep: true,
            handler: function () {
                this.star = this.isStar(
                    this.mapppingStarConv,
                    this.dataEmail.id,
                    "cid"
                );
            },
        },
        listSelectedMail: {

        }
    },
    computed: {
        ...mapGetters([
            "currentActiveEmail",
            "mapppingStarConv",
            "showFragments",
            "displayTimeInMailList",
            "localeCurrent",
            "preference",
            "colorMessagesEnabledv",
            "tags",
            "listTag",
            "activeNodeSidebar",
            "listSelectedMail",
            "listEmailSearch"
        ]),
        addressName() {
            if (this.listEmailAvatar?.length > 3) {
                const numberAddress = this.listEmailAvatar.length;
                let sub = this.to.slice(0, 3).map((e) => this.getDisplayName(e));
                sub = [
                    ...sub,
                    this.$t("zimbraNokey.zimbra_mailbox_others", {
                        number: numberAddress - 3,
                    }),
                ];
                return sub.join(", ");
            } else {
                const address = this.listEmailAvatar.map((e) => this.getDisplayName(e)) || [];
                return address.join(", ");
            }
        },
        isLastSelected() {
            return this.dataEmail.id === this.lastSelected?.id;
        },
        isGroup() {
            return this.listEmailAvatar?.length > 1;
        },
        listEmailAvatar() {
            if (this.activeNodeSidebar?.id === FOLDER_DEFAULT.SENT
                && this.activeNodeSidebar?.rid != FOLDER_DEFAULT.DRAFTS
            ) {
                return this.to;
            } else {
                return this.from;
            }
        }
    },
    methods: {
        showExcerpt() {
            if (this.listSelectedMail.findIndex(x => x.id == this.dataEmail.id) > -1) {
                this.showPopoverExcerpt = true;
                document.addEventListener("mousemove", this.hiddenExcerpt)
            }
        },
        hiddenExcerpt() {
            this.showPopoverExcerpt = false;
        },
        handleToggleSubItem() {
            if (this.listSelectedMail && this.dataEmail.id == this.listSelectedMail[0].id && !this.dataEmail.toggle) {
                this.toggleSubItem();
            }
        },
        handleCloseSubItem(e) {
            e.preventDefault();
            if (this.listSelectedMail && this.dataEmail.id == this.listSelectedMail[0].id && this.dataEmail.toggle) {
                this.toggleSubItem();
            }
        },
        rightClickAddressName() {
            this.$emit("rightClickAddressName");
        },
        setColorMsg() {
            if (this.dataEmail.tn && this.colorMessagesEnabledv.content) {
                const tag = this.listTag.find(
                    (item) => item.name === this.dataEmail.tn
                );
                if (tag && tag.color) {
                    this.color = this.listColor.find(
                        (item) => item.colorNumeric === tag.color
                    )?.value;
                } else if (tag && tag.rgb) {
                    this.color = tag.rgb;
                }
                return this.color;
            }
        },
        isActive() {
            const idx = this.listSelectedMail?.findIndex(e => e.id === this.dataEmail?.id);
            return idx > -1 || (this.activeEmail && this.dataEmail?.id === this.activeEmail?.id);
            // return idx > -1;
        },
        // renderTime() {
        //     if (this.displayTimeInMailList.content === true) {
        //         this.formatTime = "MMMM DD, h:mm:ss A";
        //     } else {
        //         this.formatTime = "DD MMMM";
        //     }
        //     return this.formatDateTimeForLang(
        //         this.dataEmail.d,
        //         this.localeCurrent.content,
        //         this.formatTime
        //     );
        // },
        async toggleSubItem() {
            if (this.dataEmail.toggle) {
                this.listEmailSearch.forEach(x => {
                    if (this.dataEmail && this.dataEmail.listSubItem?.findIndex(e =>e.id == x.id) > -1) {
                        x.hidden = true;
                    }
                    if (x.id == this.dataEmail.id) {
                        x.toggle = false;
                    }
                })
                this.$store.commit("SET_LIST_EMAIL", _.cloneDeep(this.listEmailSearch));
            } else {
                this.dataEmail.toggle = true;
                if (!this.dataEmail.listSubItem || this.dataEmail.listSubItem.length == 0) {
                    const formData = {
                        // query: "in:inbox",
                        offset: 0,
                        limit: 100,
                        fetch: 0,
                        cid: `${this.dataEmail.id}`,
                    };
                    if (this.activeNodeSidebar && !_.isNil(this.activeNodeSidebar.absFolderPath)) {
                        formData.query = _.escape(
                            "in:" + `"${this.activeNodeSidebar.absFolderPath}"`
                        );
                    }
                    const searchRequest =
                        await ZimbraMailService.searchConvRequest(formData);
                    const searchActionResponse =
                        this.getResponseBody(searchRequest)[
                            "SearchConvResponse"
                        ];
                    this.dataEmail.listSubItem = searchActionResponse.m;
                    const _sortBy =
                        this.preference.zimbraPrefConversationOrder ==
                        CONV_ORDER.DATE_ASC
                            ? "asc"
                            : "desc";
                    this.dataEmail.listSubItem = _.orderBy(
                        this.dataEmail.listSubItem,
                        ["d"],
                        [_sortBy]
                    );
                    this.$store.commit(
                        "PUSH_MAPPPING_STAR_CONV",
                        this.computeListStar(this.dataEmail.listSubItem, "id")
                    );
                    let listEmailSearch = this.listEmailSearch;
                    let indexBefore = this.dataEmail.index;
                    listEmailSearch[this.dataEmail.index] = this.dataEmail;
                    for(let index in this.dataEmail.listSubItem) {
                        this.dataEmail.listSubItem[index] = {...this.dataEmail.listSubItem[index], isSub: true};
                        indexBefore++;
                        listEmailSearch.splice(indexBefore, 0, this.dataEmail.listSubItem[index]);
                    }
                    this.$store.commit("SET_LIST_EMAIL", _.cloneDeep(listEmailSearch));
                } else {
                    this.listEmailSearch.forEach(x => {
                        if (this.dataEmail && this.dataEmail.listSubItem?.findIndex(e =>e.id == x.id) > -1) {
                            x.hidden = false;
                        }
                        if (x.id == this.dataEmail.id) {
                            x.toggle = true;
                        }
                    })
                    this.$store.commit("SET_LIST_EMAIL", _.cloneDeep(this.listEmailSearch));
                }
            }
        },
        handleSelectItem() {
            this.$emit("handleSelectItem", this.dataEmail);
        },
        initialData(data) {
            if (data && data.e) {
                const e = Array.isArray(data.e) ? data.e : [data.e];
                this.from = e.filter((x) => x.t == "f");
                this.to = e.filter((x) => x.t == "t");
                this.cc = e.filter((x) => x.t == "c");
                this.bcc = e.filter((x) => x.t == "b");
                this.star = this.checkFlagsContainKey(
                    data.f,
                    EMAIL_FLAG.FLAGGED
                );
            } else {
                this.from = [];
                this.to = [];
                this.cc = [];
                this.bcc = [];
            }
            if (data.mp && data.mp.mp && data.mp.mp.length > 1) {
                this.mp = data.mp.mp[1];
            }
            this.dataEmail.isUnread = this.checkFlagsContainKey(data.f, EMAIL_FLAG.UNREAD);
            this.dataEmail.isUrgent = this.checkFlagsContainKey(data.f, EMAIL_FLAG.URGENT);
            this.dataEmail.isLowPriority = this.checkFlagsContainKey(data.f, EMAIL_FLAG.LOW_PRIORITY);
            this.dataEmail.isAttach = this.checkFlagsContainKey(data.f, EMAIL_FLAG.ATTACHMENT);
        },
        async toggleStar() {
            const formData = {
                id: `${this.dataEmail.id}`,
                op: `${this.star ? "!" : ""}flag`,
            };
            const convRequest = !this.dataEmail.isConv
                ? await ZimbraMailService.msgActionRequest(formData)
                : await ZimbraMailService.convActionRequest(formData);
            const fieldResponse = !this.dataEmail.isConv ? "MsgActionResponse" : "ConvActionResponse";
            const convResponse =
                this.getResponseBody(convRequest)[fieldResponse];
            if (convResponse) {
                this.star = !this.star;
                const msg = this.star
                    ? this.$t("zimbraNokey.zimbra_mailbox_assignStarSuccess")
                    : this.$t("zimbraNokey.zimbra_mailbox_unassignStarSuccess");
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: msg,
                    type: "success",
                });
                this.$store.commit("UPDATE_FLAG_STAR", {
                    cid: this.dataEmail.id,
                    star: this.star,
                    field: "cid",
                });
                this.$emit("afterToggleStar", { star: this.star, id: this.dataEmail.id });
            }
        },
        actionItemEmail(data, actionType) {
            this.$store.commit("SET_LIST_SELECTED_MAIL", [data]); // fix bug 1255
            this.$root.$emit("handleNavbarAction", actionType);
        },
    },
};
</script>
<style>
.zimbra-item-mail {
    border-top: 1px gray solid;
    min-height: 60px;
}
.item-inbox-after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1.6rem;
    width: calc(100% - 3.2rem);
    height: 1px;
    background-color: #ebedf3;
}
</style>
