<template>
    <el-dialog
        width="800px"
        :title="$t('zimbra.zmMsg.attachMail')"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
        class="zimbra-dialog zm-dialog-attach-email"
        @close="onclose"
        :destroy-on-close="true"
        @opened="opened"
        :close-on-click-modal="false"
    >
        <div>
            <el-row>
                <el-col :span="10">
                    <input
                        ref="refInputKeyword"
                        class="el-text-control form-control"
                        v-model.trim="keyword"
                        @keyup.enter="handleSearchKeyWord"
                    />
                </el-col>
                <el-col :span="4" class="pl-3">
                    <el-button
                        class="el-button btn-email default"
                        @click="handleSearchKeyWord"
                        >{{ $t("zimbra.zhMsg.search") }}</el-button
                    >
                </el-col>
                <el-col :span="4">
                    <el-button
                        class="el-button btn-email primary"
                        type="primary"
                        @click="handleViewEmail"
                    >
                        {{ $t("zimbraNokey.zimbra_zimbraNokey_viewEmail") }}
                    </el-button>
                </el-col>
                <el-col :span="6" class="text-right">
                    <el-button
                        :class="['el-button', currentPage === 0 ? 'noclick' : '']"
                        @click="previousPage"
                    >
                        <i class="fi fi-rr-angle-double-small-left"></i>
                    </el-button>
                    <span class="pl-3 pr-3">{{start}} - {{end}}</span>
                    <el-button
                        :class="['el-button', isLastPage ? 'noclick' : '']"
                        @click="nextPage"
                    >
                        <i class="fi fi-rr-angle-double-small-right"></i>
                    </el-button>
                </el-col>
            </el-row>
            <el-row class="pt-3">
                <el-col :span="10" class="border-col">
                    <div class="tree-choose-wrap">
                        <TreeChooseFolder
                            ref="refTreeFolder"
                            :highlight-default="true"
                            :show-info-folder="true"
                            :show-textbox-search="false"
                            :listIdFolderHidden="[FOLDER_DEFAULT.TAG, FOLDER_DEFAULT.ROOT ]"
                            @onSelectFolder="onSelectFolder"
                        ></TreeChooseFolder>
                    </div>
                </el-col>
                <el-col :span="14" class="pl-3 zimbra-list-mail border-col">
                    <!-- <perfect-scrollbar> -->
                    <ListViewOnly ref="refListMailBox" />
                    <!-- </perfect-scrollbar> -->
                </el-col>
            </el-row>
        </div>
        <div v-html="$t('zimbra.zhMsg.attachmentLimitMsg', [maxSize])"></div>
        <span slot="footer" class="dialog-footer">
            <el-button class="el-button btn-email default" @click="onclose">{{
                $t("zimbra.zhMsg.actionTaskCancelTT")
            }}</el-button>
            <el-button
                class="el-button btn-email primary"
                type="primary"
                @click="handleAttachEmail"
            >
                {{ $t("zimbra.zmMsg.attach") }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import CommonUtils from "@/utils/common-utils";
import EmailUtils from "@/utils/email-utils";
import TreeChooseFolder from "@/components/modules/layouts/components/TreeChooseFolder";
import ListViewOnly from "@/components/modules/email/mail-box/list-mail-box/ListViewOnly";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import { mapGetters } from "vuex";
import {
    FOLDER_DEFAULT,
} from "@/utils/constants";
import _ from "lodash";
export default {
    name: "DialogEmailSelector",
    mixins: [CommonUtils, EmailUtils],
    props: {
        dialogFormVisibleProps: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TreeChooseFolder,
        ListViewOnly,
    },
    data() {
        return {
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            dialogFormVisible: this.dialogFormVisibleProps,
            keyword: "",
            start: 0,
            end: 0,
            currentPage: 0,
            pageSize: 50,
            query: "",
            listEmail: [],
            isLastPage: false
        };
    },
    computed: {
        ...mapGetters(["dataSidebarInBox", "maxSizeAttach"]),
        maxSize() {
            return this.formatSize(this.maxSizeAttach);
        }
    },
    mounted() {},
    watch: {
        dialogFormVisibleProps(val) {
            this.dialogFormVisible = val;
        },
    },
    methods: {
        onclose() {
            this.$emit("onclose");
        },
        opened() {
            // const folderInbox = this.dataSidebarInBox.find(e => e.id === FOLDER_DEFAULT.INBOX);
            this.keyword = "in:\"Inbox\"";
            this.query = `inid:${FOLDER_DEFAULT.INBOX}`;
            this.prepareSearch();
            this.$refs.refTreeFolder.setCurrentKey(FOLDER_DEFAULT.INBOX);
        },
        handleSearchKeyWord() {
            this.$refs.refInputKeyword.blur();
            this.query = _.escape(this.keyword);
            this.prepareSearch();
        },
        onSelectFolder(node) {
             if (
                node.id === FOLDER_DEFAULT.ROOT ||
                node.id === FOLDER_DEFAULT.EXTEND ||
                node.isRootSearchFolder
            ) {
                return;
            } else if (node.absFolderPath == '/Flagged') {
                this.keyword = "is:flagged";
                this.query = "is:flagged";
            } else {
                this.keyword = `in:"${node.absFolderPath.toString().replace("/", "")}"`;
                this.query = `inid:${node.id}`;
            }
            this.prepareSearch();
        },
        handleAttachEmail() {
            const listSelected = this.$refs.refListMailBox.getListActiveEmail();
            if (!listSelected || listSelected.length === 0) {
                return;
            }
            this.$emit("handleAttachEmail", listSelected);
        },
        prepareSearch() {
             const formData = {
                types: "message",
                limit: `${this.pageSize}`,
                offset: "0",
                query: this.query,
                currentPage: 0
            };
            this.handleSearch(formData);
        },
        handleSearch(formData) {
            ZimbraMailService.searchRequest(formData).then((searchRequest) => {
                const searchResponse =
                    this.getResponseBody(searchRequest)["SearchResponse"];
                let listEmail = searchResponse.m || [];
                listEmail = Array.isArray(listEmail) ? listEmail : [listEmail];
                this.listEmail = listEmail;
                this.listActiveMail = [];
                if (this.$refs.refListMailBox) {
                    this.$refs.refListMailBox.setListEmail(listEmail);
                }
                this.currentPage = formData.currentPage;
                this.start = this.pageSize * this.currentPage + 1;
                this.end = this.pageSize * this.currentPage + listEmail.length;
                this.isLastPage = this.pageSize != listEmail.length;
            });
        },
        previousPage() {
            const currentPage = this.currentPage - 1;
            const formData = {
                types: "message",
                limit: `${this.pageSize}`,
                offset: currentPage * this.pageSize,
                query: this.query,
                currentPage: currentPage
            };
            this.handleSearch(formData);
        },
        nextPage() {
            const currentPage = this.currentPage + 1;
            const formData = {
                types: "message",
                limit: `${this.pageSize}`,
                offset: currentPage * this.pageSize,
                query: this.query,
                currentPage: currentPage
            };
            this.handleSearch(formData);
        },
        handleViewEmail() {
            const listSelected = this.$refs.refListMailBox.getListActiveEmail();
            this.lanchNewWindow(listSelected);
        }
    },
};
</script>