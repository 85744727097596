<template>
    <div>
        <template v-if="isLoading">
            <div class="pl-4 pr-4 pt-4">
                <el-skeleton :rows="12" :loading="isLoading" animated />
            </div>
        </template>
        <template v-else>
            <!-- Tree Contact -->
            <el-tree
                ref="vuetree"
                class="tree-wrap"
                id="tree"
                node-key="id"
                :data="contactFolderTree"
                :props="defaultProps"
                @node-click="nodeClick"
                @node-contextmenu="rightClick"
                highlight-current
                :allow-drop="allowDrop"
                :allow-drag="allowDrag"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpand"
            >
                <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data.id) ? '': 'zm-dragable'}`" slot-scope="{ node, data }"
                    v-bind:id="data.id"
                    v-bind:type="data.view"
                    v-bind:name="data.name"
                >
                    <el-popover
                        placement="bottom-start"
                        :open-delay="500"
                        trigger="hover"
                        :disabled="
                        data.id === FOLDER_DEFAULT.ROOT || checkPoper
                        "
                    >
                        <div>
                            <b>{{ node.label }}</b> <br />
                            <div
                                v-if="
                                    (data.id != FOLDER_DEFAULT.TRASH) &
                                    (data.id != FOLDER_DEFAULT.MAILING_LIST)
                                "
                                class="d-flex"
                            >
                                <span class="folder-info-left">{{
                                    $t("zimbra.zhMsg.addressBooksLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    data.n || 0
                                }}</span>
                            </div>
                            <div
                                v-if="data.id == FOLDER_DEFAULT.TRASH"
                                class="d-flex"
                            >
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.items")
                                }}</span>
                                <span class="folder-info-right">{{
                                    data.n || 0
                                }}</span>
                            </div>
                            <div v-if="data.s" class="d-flex">
                                <span class="folder-info-left">{{
                                    $t("zimbra.zmMsg.fSizeLabel")
                                }}</span>
                                <span class="folder-info-right">{{
                                    formatSize(data.s)
                                }}</span>
                            </div>
                        </div>
                        <span slot="reference" class="custom-tree-node">
                            <span
                                v-if="
                                    data.id != FOLDER_DEFAULT.CONTACTS &&
                                    data.id != FOLDER_DEFAULT.EMAIL_CONTACT &&
                                    data.id != FOLDER_DEFAULT.ROOT &&
                                    data.id != FOLDER_DEFAULT.TRASH &&
                                    data.id != FOLDER_DEFAULT.MAILING_LIST &&
                                    data.isLink == undefined
                                "
                            >
                                <i
                                    :class="[
                                        'ic-folder ic-with-color',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    :style="`padding-right: 30px; margin-left: -5px; zoom: 0.9; filter: ${convertHexToFilterCss(
                                        data
                                    )};  --icColor: ${convertHexToFilterCss(data)}`"
                                ></i>
                            </span>
                            <span v-if="data.isLink">
                                <i
                                    :class="[
                                        'ic-folder-copy ic-with-color',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    :style="`padding-right: 30px; margin-left: -6px;filter: ${convertHexToFilterCss(
                                        data
                                    )}; --icColor: ${convertHexToFilterCss(data)}`"
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.CONTACTS">
                                <i
                                    :class="[
                                        'ic-contact ic-with-color',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    :style="`
                                        padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                        data
                                    )}; --icColor: ${convertHexToFilterCss(data)}`"
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.MAILING_LIST">
                                <i
                                    :class="[
                                        'ic-distribution',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    style="
                                        padding-right: 30px; margin-left: -8px;
                                    "
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.EMAIL_CONTACT">
                                <i
                                    :class="[
                                        'ic-email-contact',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    :style="`
                                        padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                        data
                                    )}; --icColor: ${convertHexToFilterCss(data)}`"
                                ></i>
                            </span>
                            <span v-if="data.id == FOLDER_DEFAULT.TRASH">
                                <i
                                    :class="[
                                        'ic-trash',
                                        isActive(data) ? 'active' : '',
                                    ]"
                                    style="padding-right: 30px; margin-left: -7px;  zoom: 1.05"
                                ></i>
                            </span>
                            <span
                                v-if="(data.isLink && data.perm) || data.isLink== undefined"
                            >
                                <span style="margin-left: 5px">
                                    {{ node.label }}
                                </span>
                            </span>
                            <span
                                v-if="data.isLink && data.perm == undefined"
                                style="text-decoration: line-through"
                                >{{ node.label }}
                            </span>
                            <button
                                v-if="!isContactSelector"
                                class="icon-caret float-right"
                                :id="`caret_${data.id}`"
                                @click.stop="(e) => rightClick(e, data, node)"
                            >
                                <i class="el-icon-caret-bottom"></i>
                            </button>
                        </span>
                    </el-popover>
                </div>
            </el-tree>

            <!-- Tree-Tag -->
            <el-tree
                v-if="showTreeTag"
                ref="vuetreeTag"
                class="tree-zimlet tree-wrap"
                node-key="id"
                id="tree"
                :data="folderTag"
                :props="defaultProps"
                @node-click="nodeClick"
                @node-contextmenu="rightClick"
                :allow-drop="allowDrop"
                :allow-drag="allowDrag"
                :expand-on-click-node="false"
                default-expand-all
            >
                <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data.id) ? '': 'zm-dragable'}`" slot-scope="{ node, data }"
                    v-bind:id="data.id"
                    v-bind:type="data.type"
                    v-bind:name="data.name"
                >
                    <el-popover
                        placement="bottom"
                        popper-class="zm-tooltip"
                        :open-delay="500"
                        trigger="hover"
                        :disabled="checkPoper"
                    >
                        <b>{{ node.label }}</b>
                        <div slot="reference">
                            <span v-if="
                                data.id == FOLDER_DEFAULT.TAG ||
                                data.type == SIDEBAR_NODE.TAG
                            ">
                                <i
                                    :class="['ic-tag', isActive(data) ? 'active' : '']"
                                    :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                        data
                                    )}`"
                                ></i>
                            </span>
                            <span>{{ node.label }}</span>
                            <button
                                class="icon-caret float-right"
                                :id="`caret_${data.id}`"
                                @click.stop="(e) => rightClick(e, data, node)"
                            >
                                <i class="el-icon-caret-bottom"></i>
                            </button>
                        </div>
                    </el-popover>

                </div>
            </el-tree>
            <!-- tree folder search -->
            <el-tree
                v-if="showTreeSearch"
                class="tree-wrap"
                ref="tree"
                :data="treeFolderSearchData"
                node-key="id"
                :props="defaultProps1"
                :default-expanded-keys="[FOLDER_DEFAULT.ROOT]"
                @node-click="nodeClick"
                @node-contextmenu="rightClick"
                :expand-on-click-node="false"
                highlight-current
            >
                <div :class="`zm-unselect zm-folder-info zm-dropable ${isFolderDefault(data.id) ? '': 'zm-dragable'}`" slot-scope="{ node, data }"
                    :type="FOLDER_VIEW.SEARCH"
                    v-bind:id="data.id"
                    v-bind:name="data.name"
                >
                    <span v-if="data.id != FOLDER_DEFAULT.ROOT">
                        <i
                            class="ic-folder"
                            :style="`padding-right: 30px; margin-left: -8px;filter: ${convertHexToFilterCss(
                                data
                            )}`"
                        ></i>
                    </span>
                    <span>{{ node.label }}</span>
                    <button
                        class="icon-caret float-right"
                        :id="`caret_${data.id}`"
                        @click.stop="(e) => rightClick(e, data, node)"
                    >
                        <i class="el-icon-caret-bottom"></i>
                    </button>
                </div>
            </el-tree>
            <!-- Tree-Zimlet -->
            <div v-if="showTreeZimlet">
                <ZimletTree
                    :highlightDefault="highlightDefault"
                    :showInfoFolder="showInfoFolder"
                />
            </div>
        </template>
    </div>
</template>

<script>
import CommonUtils from "@/utils/common-utils";
import EmailUltils from "@/utils/email-utils";
import ZimletTree from "@/components/modules/calendar/components/menu-left-calendar/zimlet-tree.vue"
import { mapGetters } from "vuex";
import _ from "lodash"
import {
    FOLDER_DEFAULT,
    SETTING_ZIMLET,
    SIDEBAR_NODE,
    FOLDER_VIEW
} from "@/utils/constants";
// import _ from "lodash";
export default {
    mixins: [CommonUtils, EmailUltils],
    props: {
        highlightDefault: {
            type: Boolean,
            default: false,
        },
        showInfoFolder: {
            type: Boolean,
            default: false,
        },
        showTreeTag: { // Hiển thị thư mục tag
            type: Boolean,
            default: true,
        },
        showTreeSearch: { // Hiển thị thư mục search
            type: Boolean,
            default: true,
        },
        showTreeZimlet: { // Hiển thị thư mục zimlet
            type: Boolean,
            default: true,
        },
        isContactSelector: { // Sử dụng trong màn hình đính kèm số liên hệ
            type: Boolean,
            default: false,
        },
    },
    components: {
        ZimletTree
    },
    data() {
        return {
            defaultProps: {
                children: "folder",
                label: "name",
                label2: "shortName",
            },
            defaultProps1: {
                children: "search",
                label: "name",
                label2: "shortName",
            },
            defaultExpand: [FOLDER_DEFAULT.ROOT],
            defaultExpandTreeTag: [-1],
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SETTING_ZIMLET: SETTING_ZIMLET,
            SIDEBAR_NODE: SIDEBAR_NODE,
            FOLDER_VIEW: FOLDER_VIEW,
            data: [],
            checkPoper: false,
            node: {},
            treeFolderSearchData: [],
        };
    },
    mounted() {
        this.$root.$on("extendFolderContact", () => {
            this.node.expanded = true;
        });
        this.$root.$on("setCurrentKeyMainTree", (id) => {
            setTimeout(() => {
                this.$refs.vuetree.setCurrentKey(id);
            }, 200);
            setTimeout(() => {
                this.$refs.vuetreeTag.setCurrentKey(id);
            }, 200);
        });
        document.body.addEventListener("click", () => {
            if (this.checkPoper)
                this.checkPoper = false
        })
        // this.$root.$on("actionSetActiveNode", ({ nodeExtend, nodeId }) => {
        //     this.actionSetActiveNode(nodeExtend, nodeId);
        // });
        this.$root.$on("onUpdateMoveContactSearchFolder", this.onUpdateMoveContactSearchFolder)
        this.$root.$on("onDeleteContactSearchFolder", this.onDeleteContactSearchFolder)
    },
    destroyed () {
        this.$root.$off("onUpdateMoveContactSearchFolder")
        this.$root.$off("onDeleteContactSearchFolder")
    },
    computed: {
        ...mapGetters([
            "dataSidebarContact",
            "dataTableCurrent",
            "activeNodeContact",
            "dataSidebarExtendContact",
            "folderSearches"
        ]),
        folderTag() {
            if (this.dataSidebarExtendContact !== null && this.dataSidebarExtendContact.length > 0) {
                let dataTag = [this.dataSidebarExtendContact[0].folder.find((x) => x.id == -2)];
                dataTag = _.orderBy(dataTag[0].folder, [(n) => {return n.name.toString().toLowerCase()}], ["asc"])
                const rootTag = [{
                    id: FOLDER_DEFAULT.TAG,
                    name: this.$t('zimbra.zmMsg.tags'),
                    folder: dataTag
                }]
                return rootTag
            }
            return []
        },
        contactFolderTree() {
            if (!this.dataSidebarContact || this.dataSidebarContact.length === 0) {
                return [];
            }
            // Nếu là dialog đính kèm số liên hệ thì bỏ node cha "Danh sách liên hệ"
            if (this.isContactSelector) {
                return this.dataSidebarContact[0].folder.filter(x => x.id != 12);
            } else {
                return this.dataSidebarContact;
            }
        },
        isLoading() {
            const _contact = this.dataSidebarContact?.length || 0;
            const _extend = this.dataSidebarExtendContact?.length || 0;
            // const _zimlet = this.tableData?.length || 0;
            return (_contact + _extend) === 0;
        }
    },
    async created() {
        await this.innit();
        setTimeout(() => {
            if (this.$refs.vuetree) {
                this.activeNodeContact ? this.$refs.vuetree.setCurrentKey(this.activeNodeContact.id) :
                    this.$refs.vuetree.setCurrentKey(FOLDER_DEFAULT.CONTACTS)
            }
        }, 1500);
    },
    methods: {
        async innit() {
            const folderSearches = await this.getFolderSearchApi();
            const contactChildrenSearch = folderSearches[0]?.search? folderSearches[0].search.filter(x => x.types == FOLDER_VIEW.CONTACT) : []
            this.treeFolderSearchData = [
                {
                    ...folderSearches[0],
                    search: contactChildrenSearch
                }
            ];
        },
        extendAll(node) {
            node.expanded = true;
            if (node.childNodes) {
                node.childNodes.forEach((x) => {
                    this.extendAll(x);
                });
            }
        },
        rightClick(e, data, node) {
            this.closeAllRightMenu();
            this.node = node;
            this.checkPoper = true
            if (data.id == FOLDER_DEFAULT.TAG || data.l == FOLDER_DEFAULT.TAG) {
                this.$root.$emit("showRightMenuTag", e, data, node);
                this.$store.commit("SET_ACTIVE_NODE_SIDEBAR_RIGHTCLICK", data);
            } else if (
                data.id == FOLDER_DEFAULT.ROOT &&
                !data.isRootSearchFolder
            ) {
                this.$root.$emit("showRightMenuContactFolder", e);
            } else {
                this.$root.$emit("showRightMenuContact", e, data, node);
                // this.$store.commit("SET_TAGS", data);
            }
            // setTimeout(()=>{
            //      this.checkPoper = false
            // },200)
        },
        closeAllRightMenu() {
            this.$root.$emit("closeRightMenuContact");
            this.$root.$emit("closeRightMenuTag");
            this.$root.$emit("closeRightMenuContactFolder");
        },
        allowDrag() {
            return true;
        },
        allowDrop(draggingNode, dropNode, type) {
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.parent.id === dropNode.parent.id) {
                    return type === "prev" || type === "next";
                }
            } else {
                return false;
            }
        },
        nodeClick(data) {
            
            this.closeAllRightMenu();
            this.checkPoper = false
            if (data.id != FOLDER_DEFAULT.ROOT) {
                this.handleNodeClick(data, false);
            }
        },
        handleNodeClick(data, isExtendTree) {
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
            this.$refs.vuetreeTag && this.$refs.vuetreeTag.setCurrentKey(null);
            if (isExtendTree) {
                this.$refs.vuetreeTag &&
                    this.$refs.vuetreeTag.setCurrentKey(data.id || 0);
            } else {
                this.$refs.vuetree &&
                    this.$refs.vuetree.setCurrentKey(data.id || 0);
            }
            if (data.isLink && data.perm == undefined) {
                this.$confirm(
                    this.$t("zimbraNokey.zimbra_sidebar_warningRegain", {
                        name: data.name,
                    }),
                    this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                    {
                        confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                        cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$root.$emit("deleteFolder", data);
                    })
                    .catch(() => {
                        this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(this.activeNodeContact.id || 0);
                     });
            } else {
                if (this.$listeners.onSelectFolder) {
                    this.$emit("onSelectFolder", data);
                } else {
                    if (data.id == FOLDER_DEFAULT.TAG || data.l == FOLDER_DEFAULT.TAG) {
                        this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
                        this.$store.commit("SET_ACTIVENODECONTACT", data);
                    } else {
                        this.$store.commit("SET_ACTIVE_NODE_SIDEBAR", data);
                        this.$store.commit("SET_ACTIVENODECONTACT", data);
                    }
                }
                if (this.$listeners.onSelectFolder) {
                    this.$emit("onSelectFolder", data);
                } else {
                    this.$root.$emit("handleSelectContactNode", data, true);
                }
            }
        },
        isActive(node) {
            return node.id === this.activeNodeContact?.id;
        },
        setCurrentKeyDefault(type, data) {
            this.$refs.vuetreeTag.setCurrentKey(null);
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(null);
            this.$store.commit(type, data);
        },
        setCurrentKey(key) {
            this.$refs.vuetree && this.$refs.vuetree.setCurrentKey(key);
        },
        convSearchToFolder(folder) {
            folder?.forEach(e => {
                if (e.search.length > 0) {
                    e.folder = [...e.search]
                    this.convSearchToFolder(e.folder)
                } else {
                    return folder
                }
            });
            return folder;
        },
        /**
         * hàm kiểm tra folder có phải folder mặc định không
         */
        isFolderDefault(folderId) {
           return Object.values(FOLDER_DEFAULT).includes(folderId);
        },

        /**
         * action cập nhật lại node vừa di chuyển
         */
        onUpdateMoveContactSearchFolder(node) {
            // xóa vị trí node cũ
            this.deleteNode(this.treeFolderSearchData[0], node)
            // thêm node vào vị trí mới
            this.addNode(this.treeFolderSearchData[0], node)
            // Cập nhật lại state danh sách trong store
            this.getFolderSearchApi()
        },

        /**
         * action xóa node vừa xóa
         */
        onDeleteContactSearchFolder(node) {
           this.deleteNode(this.treeFolderSearchData[0], node)
            // Cập nhật lại state danh sách trong store
            this.getFolderSearchApi()
        },

        /**
         * hàm xóa node được chọn
         */
        deleteNode(node, nodeInfo) {
            if(!node.search) {
                return;
            }
            if(node.search.find(x => x.id == nodeInfo.id)) {
                node.search = node.search.filter(x => x.id != nodeInfo.id)
                return;
            }
            if(node.search) {
                node.search.forEach((x) => {
                    this.deleteNode(x, nodeInfo);
                });
            }
        },

        /**
         * hàm thêm node được chọn
         */
        addNode(node, nodeInfo) {
            if(!node.search) {
                return;
            }
            if(node.id == nodeInfo.l) {
                node.search.push(nodeInfo)
                return;
            }
            if (node.search) {
                node.search.forEach((x) => {
                    this.addNode(x, nodeInfo);
                });
            }
        },
    },
};
</script>

<style>
</style>
